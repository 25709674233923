<template>
<div class='cang'>
  <div id="title">
    <Title :msg='{title: $t("m.index.Interactive_warehouse") }'/>
  </div>
  <div class="carousel_list" v-if="false">
    <swiperMainVue :swiperData='imgData' />
  </div>
  <div class="carousel_list" v-loading="listLoading">
    <el-carousel :interval="5000" type="card" height="350px">
      <el-carousel-item v-for="item in imgData" :key="item">
        <h3>{{ item.name }}</h3>
        <el-image :src="item.albumPics" fit="fill" />
      </el-carousel-item>
    </el-carousel>
  </div>
</div>
</template>

<script>
import http from '../../api/http'
import swiperMainVue from '../../components/swiperMain.vue'
import Title from '../../components/Title'
// 分页查询仓储信息
const warehouseList = (data) => {
  return http.get('/mall-portal/warehouse/list', data)
}

export default {
  name: 'Cangku',
  data () {
    return {
      imgData: [{}, {}, {}],
      listQuery: {
        pageNum: 1,
        pageSize: 100
      },
      listLoading: false
    }
  },
  components: { Title, swiperMainVue },
  methods: {
    initData () {
      this.listLoading = true
      warehouseList(this.listQuery).then((res) => {
        console.log(res)
        if (res.data.code === 200) {
          this.imgData = res.data.data.records
          for (const item of this.imgData) {
            // console.log(item)
            item.albumPics = item.albumPics.split(',')[0]
          }
        }
        this.listLoading = false
      }).catch((err) => {
        this.listLoading = false
        console.log(err)
      })
    }
  },
  mounted () {
    this.initData()
  },
  setup (props, ctx) {
    return {

    }
  }
}
</script>
<style lang='scss' scoped>
//@import ''; 引入公共css类
.cang{
  .carousel_list{
    padding: 20px 10px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    .el-carousel{
      flex: 1;
      // width: 100%;
      // height: 400px;
      .el-carousel__container{
        // display: flex;
        // justify-content: center;
        .el-carousel__item{
            .el-image{
              display: block;
              margin: 0 auto;
              width: 100%;
              height: 100%;
            }
            h3{
              font-weight: 400;
              font-size: 16px;
              color: #828282;
              display: block;
              height: 25px;
              text-align: center;
            }
            img{
              display: block;
              margin: 0 auto;
              width: 100%;
              height: 100%;
            }
        }
      }
    }
  }
}

</style>
