<template>
  <div class="shop">
    <div class="shop_title">
      <p>
        <span>{{$t('m.index.Global_high_supplier_recommendation')}}</span>
      {{$t('m.index.cooperating_suppliers_with_categories')}}</p>
      <i class="zi2">{{this.$t('m.index.more')}}&gt;</i>
    </div>
      <div class="shopshow">
        <el-scrollbar>
          <ul v-loading="loading" class="gyshopshowul">
            <li v-for="(item,index) in shopList" :key="index" @click="toSpecial(item)">
              <img :src="item.logo" :class="item.status?'notdata':''">
            </li>
          </ul>
        </el-scrollbar>
      </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue-demi'
import http from '../../api/http'

// 获取优质供应商
const umscompanyinfo = (data) => {
  return http.post('/mall-merchant/search/umscompanyinfo/list', data)
}

export default {
  name: 'Gongying',
  data () {
    return {
      loading: ref(true),
      shopList: reactive([])
    }
  },
  methods: {
    async initData () {
      const data = {
        pageNum: 1,
        pageSize: 6
      }
      const res = await umscompanyinfo(data)
      // console.log(res)
      if (res.data.code === 200) {
        this.shopList = res.data.data.list
        const num = this.shopList.length
        if (this.shopList.length < 6) {
          for (let i = 0; i < 6 - num; i++) {
            this.shopList.push({
              logo: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/%E5%9B%BE%E7%89%87/%E6%9A%82%E6%97%A0%E8%AF%84%E8%AE%BA.png' || 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/imges/nouser.png',
              status: true
            })
          }
        }
        this.loading = false
      }
    },
    toSpecial (item) {
      console.log(item)
      // 供应商
      // if (!item.status) {
      //   const routeData = this.$router.resolve({
      //     path: '/special',
      //     query: {
      //       memberId: item.memberId
      //     }
      //   })
      //   window.open(routeData.href, '_blank')
      // }
    }
  },
  mounted () {
    this.initData()
  },
  setup () {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../style/viriables.scss';

.shop{
  padding: 20px;
  height: 70px;
  box-sizing: border-box;
  .shop_title{
    margin-bottom: 20px;
    p{
      display: inline-block;
      height: 12px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 39px;
      span{
        width: 180px;
        height: 20px;
        font-size: 20px;
        // font-family: Microsoft YaHei;
        font-weight: bold;
        // color: #333333;
        color: #3C1C0B;
        line-height: 39px;
        margin-right: 20px;
      }
    }
    .zi2{
      text-align: right;
      line-height: 39px;
      float: right;
      cursor: pointer;
      color: #c3c3c3;
    }
  }
  // .el-scrollbar{
  //   width: 100%;
  //   height: 153px;
  //   .el-scrollbar__wrap{
  //     width: 100%;
  //     height: 153px;
  //   }
  // }
  .shopshow{
    // width: 1200px;
    // min-width: 1160px;
    border-top: 1px solid $bgColor;
    height: 153px;
    background: #FFFFFF;
    .el-scrollbar{
      // width: 100%;
      height: 153px;
      .el-scrollbar__wrap{
        // width: 100%;
        height: 153px;
        .el-scrollbar__view{
          overflow: auto;
          .gyshopshowul{
            // width: 100%;
            height: 153px;
            align-items: center;
            display: flex;
            justify-content: space-around;
            li{
              // flex: 1;
              height: 86%;
              // width: 16%;
              width: 186px;
              display:flex;
              justify-content: center;
              align-items: center;
              // margin: 0 1px;
              border-left: 1px solid $bgColor;
              border-right: 1px solid $bgColor;
              box-shadow: 0px 5px 11px 0px rgba(0,0,0,0.06);
              cursor: pointer;
              img{
                // width: 100%;
                height: 100%;
              }
              .notdata{
                // opacity: 0.4;
                // padding: 30px 40px;
                box-sizing: border-box;
                height: 100%;
                max-height: 153px;
                // background: #3333334f;
              }
            }
            :last-child{
              border-right: 0;
            }
            :first-child {
              border-left: 0;
            }
          }
        }
      }
    }

  }
}
</style>
