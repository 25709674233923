<template>
  <div class="parent">

    <div class="parent01" v-if="show">
      <!-- <div id="user">
        <span class="icon">
          <el-icon><avatar /></el-icon>
        </span>
        <input type="text" placeholder="请输入账户名">
      </div>
      <div id="pwd">
        <span class="icon">
          <el-icon><lock /></el-icon>
        </span>
        <input type="password" placeholder="请输入密码">
        <span class="yan">
        </span>
      </div> -->
      <!-- <p id="wangji"><a>忘记密码?</a></p> -->
      <div id="btn">
        <img :src="User.avatar" alt="">

        <button id="log" @click="this.$router.push('/loginandregister')">{{$t('m.search.login')}}</button>
        <!-- <button id="reg" @click="this.$router.push('/loginandregister')">注册</button> -->
      </div>
      <div class="btn_user_message">
        <ul>
          <li><span @click="readContract(1)">{{$t('m.search.transaction_rules')}}</span></li>
          <li><span @click="readContract(2)">{{$t('m.search.Instructions_for_membership')}}</span></li>
          <!-- <li><span>公告</span></li> -->
          <li><span @click="readContract(4)">{{$t('m.search.user_manual')}}</span></li>
        </ul>
      </div>
    </div>

    <div class="parent02" v-if="!show">
      <div>
        <img class="" :src="User.avatar" alt="">
        <p class="username">{{User.nickname}}</p>
        <button @click="closeLogin">{{$t('m.search.log_off')}}</button>
        <button @click="this.$router.push('/personalcenter/myorder')">{{$t('m.search.user_center')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue-demi'
import { getStore, removeStore, setStore } from '../tools/storage'
import ck from '../tools/cookie'
import http from '../api/http'

export default {
  name: 'RegLog',
  data () {
    return {
      User: reactive({
        id: '',
        nickname: '',
        avatar: 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/avatar/noUser.png' || 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/avatar/default.jpg'
      }),
      show: ref(true)
    }
  },
  methods: {
    closeLogin () {
      http.post('/mall-portal/sso/logout')
        .then(res => {
          // console.log(res)
          if (res.data.code === 200) {
            ck.clearCookie('token')
            removeStore('User')
            removeStore('token')
            removeStore('usermessage')
            window.location.reload()
          }
        }).catch(err => {
          console.log(err)
        })
    },
    readContract (code) {
      const fileUrl = code === 1
        ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E6%98%86%E5%B1%B1%E5%92%96%E5%95%A1-%E8%B4%B8%E6%98%93%E7%BB%86%E5%88%99.pdf'
        : code === 2
          ? 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8D%8F%E8%AE%AE.pdf'
          : code === 3
            ? ''
            : 'https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/contract/e%E8%B4%B8%E7%BD%91%E7%94%A8%E6%88%B7%E6%89%8B%E5%86%8C.pdf'
      window.open(fileUrl)
    }
  },
  mounted () {
    if (getStore('token')) {
      this.show = false
      http.get('/mall-portal/sso/info')
        .then(res => {
          // console.log(res)
          const User = {
            id: res.data.data.id,
            nickname: res.data.data.realName,
            avatar: res.data.data.icon
          }
          this.User = User
          setStore('User', User)
          setStore('usermessage', res.data.data) // 存个人信息
          // this.$router.push('/')
          // setTimeout(() => {
          //   window.location.reload()
          // }, 300)
          // timeer()
        }).catch(err => {
          console.log(err)
        })
      // console.log(getStore('User'))
      // this.User = JSON.parse(getStore('User'))
      // console.log(this.User)
    } else {
      this.show = true
    }
  }
}
</script>
<style lang="scss" scoped>
.parent{
  // background: blueviolet;
  .parent01{
    display: flex;
    flex-flow: wrap;
    #user,#pwd{
      border: 1px solid #eaeaea;
      width: 240px;
      height: 40px;
      padding: 5px 3px;
      margin-bottom: 20px;
      display: flex;
      box-sizing: border-box;
      span{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 25px;
        line-height: 25px;
        margin-right: 5px;
        text-align: center;
        // border: 1px solid black;
        // color: #eaeaea;
        .el-icon{
          font-size: 17px;
          color: #ecbe9a;
        }
      }
      input{
        background: 0;
        border: 0;
        outline: none;
        color: #FFFFFF;
      }
    }
    #pwd{
      input{
        flex: 1;
      }
      .yan{
        margin: 0;
      }
    }
    #wangji{
      text-align: right;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 39px;
    }
    #btn{
      flex: 1;
      width: 100%;
      float: left;
      display: flex;
      flex-flow: column;
      justify-content: center;

      img{
        margin:0 auto;
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      #log,#reg{
        margin:10px auto;
        width: 110px;
        height: 34px;
        font-size: 14px;
        color: #FFFFFF;
        font-weight: 500;
        border: 1px solid #C6824E;
      }
      #log{
        // margin-right: 20px;
        background: #3C1C0B;
        box-shadow: inset 0px 6px 7px 0px rgba(255,255,255,0.1);
        border-radius: 20px;
        opacity: 1;
        // background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        // border-radius: 4px;
      }
      #reg{
        background: linear-gradient(0deg, #AC703B 0%, #FFBC88 100%);
        border-radius: 4px;
      }
    }
    .btn_user_message{
      width: 100%;
      ul{
        display: flex;
        flex-flow: wrap;
        // align-items: center;
        // align-content: center;
        padding-top: 7px;
        li{
          width: 33.33%;
          height: 35px;
          text-align: center;
          font-weight: bold;
          color: #474747;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          span{
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
    }
  }
  .parent02{
    div{
      display: flex;
      flex-flow: column;
      align-items: center;
      img{
        width: 80px;
        // height: 80px;
        border-radius: 50%;
      }
      p{
        text-align: center;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #3C1C0B;
        margin: 10px 0;
      }
      button{
        margin-top: 10px;
        width: 100px;
        height: 30px;
        border: 1px solid #C6824E;
        color: #FFFFFF;
        background: #3C1C0B;
        font-size: 12px;
        border-radius: 5px;
      }
    }
  }
}
</style>
